import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import forEach from 'lodash/forEach';

const defaultWrapKey = (key, props) => (props.isLeaf ? key : `${key}.`);

const flatten = (value, wrapKey = defaultWrapKey, level = 0) => {
  if (isPlainObject(value) || isArray(value)) {
    const object = {};
    forEach(value, (v1, k1) => {
      const flat = flatten(v1, wrapKey, level + 1);
      if (isPlainObject(flat)) {
        const props = {
          isLeaf: false,
          isRoot: level === 0,
        };
        forEach(flat, (v2, k2) => {
          object[`${wrapKey(k1, props)}${k2}`] = v2;
        });
      } else {
        const props = {
          isLeaf: true,
          isRoot: level === 0,
        };
        object[wrapKey(k1, props)] = flat;
      }
    });
    return object;
  }
  return value;
};

export default flatten;
