"use strict";
/* eslint no-underscore-dangle: 0 */
Object.defineProperty(exports, "__esModule", { value: true });
const safe_buffer_1 = require("safe-buffer");
/**
 * We need this to be able to index into the buffer with a number
 * because the typescript definitions for safe-buffer don't allow it
 * for some reason.
 * @typedef {Buffer & { [i: number]: number }} BufferWithIndexSignature
 */
/**
 * Prototype class for hash functions.
 */
class Hash {
    /**
     * @param {number} blockSize
     * @param {number} finalSize
     */
    constructor(blockSize, finalSize) {
        this._block = /** @type {BufferWithIndexSignature} */ (safe_buffer_1.Buffer.alloc(blockSize));
        this._finalSize = finalSize;
        this._blockSize = blockSize;
        this._len = 0;
    }
    /**
     * @param {string | Buffer} data
     * @param {BufferEncoding} [enc]
     * @return {Hash}
     */
    update(data, enc) {
        /** @type {BufferWithIndexSignature} */
        let dataBuffer;
        let dataEnc = enc;
        if (typeof data === 'string') {
            dataEnc = dataEnc || 'utf8';
            dataBuffer = /** @type {BufferWithIndexSignature} */ (safe_buffer_1.Buffer.from(data, dataEnc));
        }
        else {
            dataBuffer = /** @type {BufferWithIndexSignature} */ (data);
        }
        const block = this._block;
        const blockSize = this._blockSize;
        const { length } = dataBuffer;
        let accum = this._len;
        for (let offset = 0; offset < length;) {
            const assigned = accum % blockSize;
            const remainder = Math.min(length - offset, blockSize - assigned);
            for (let i = 0; i < remainder; i += 1) {
                block[assigned + i] = dataBuffer[offset + i];
            }
            accum += remainder;
            offset += remainder;
            if (accum % blockSize === 0) {
                this._update(block);
            }
        }
        this._len += length;
        return this;
    }
    /**
     * @param {BufferEncoding} [enc]
     * @return {string | Buffer}
     */
    digest(enc) {
        const rem = this._len % this._blockSize;
        this._block[rem] = 0x80;
        // zero (rem + 1) trailing bits, where (rem + 1) is the smallest
        // non-negative solution to the equation (length + 1 + (rem + 1)) === finalSize mod blockSize
        this._block.fill(0, rem + 1);
        if (rem >= this._finalSize) {
            this._update(this._block);
            this._block.fill(0);
        }
        const bits = this._len * 8;
        // uint32
        if (bits <= 0xffffffff) {
            this._block.writeUInt32BE(bits, this._blockSize - 4);
            // uint64
        }
        else {
            // eslint-disable-next-line no-bitwise
            const lowBits = (bits & 0xffffffff) >>> 0;
            const highBits = (bits - lowBits) / 0x100000000;
            this._block.writeUInt32BE(highBits, this._blockSize - 8);
            this._block.writeUInt32BE(lowBits, this._blockSize - 4);
        }
        this._update(this._block);
        const hash = this._hash();
        if (enc === 'base64url') {
            return hash
                .toString('base64')
                .replace(/\+/g, '-')
                .replace(/\//g, '_')
                .replace(/=/g, '');
        }
        return enc ? hash.toString(enc) : hash;
    }
    /**
     * @param {Buffer} data
     * @return {void}
     */
    // eslint-disable-next-line no-unused-vars, class-methods-use-this
    _update(data) {
        throw new Error('_update must be implemented by subclass');
    }
    /**
     * @return {Buffer}
     */
    // eslint-disable-next-line class-methods-use-this
    _hash() {
        throw new Error('_hash must be implemented by subclass');
    }
}
exports.default = Hash;
