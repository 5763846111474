/* eslint global-require: "off" */
import forEach from 'lodash/forEach';
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink,
  Observable,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { CachePersistor, LocalForageWrapper } from 'apollo3-cache-persist';
import storage from 'localforage';
import React from 'react';
import ReactDOM from 'react-dom';
import i18next from 'i18next';
import { createBrowserHistory } from 'history';
import settings from './common/settings';
import App from './containers/App';
import resolveOnSelector from './common/utilsClient/redux/resolveOnSelector';
import configureStore from './store/configureStore';
import {
  AuthError,
  logout,
  refresh,
  selectIsAuthenticating,
  selectSessionJwt,
} from './store/token';
import isDebug from './utils/isDebug';
import { initI18next } from './utils/i18next';
import './common/logger/client/register';
import './common/fonts';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './index.css';
import notification from './utils/notification';
import logger from './common/logger';

const history = createBrowserHistory();
const cache = new InMemoryCache();
const cachePersistor = new CachePersistor({
  cache,
  storage: new LocalForageWrapper(storage),
});

let store;
let persistor;
let initialLoading;

const authLink = setContext(async (_, { headers }) => {
  await initialLoading;
  await resolveOnSelector(store, (state) => !selectIsAuthenticating(state));
  const authorization = selectSessionJwt(store.getState());
  if (!authorization) {
    throw new AuthError('Missing token');
  }
  return {
    headers: {
      ...headers,
      authorization,
    },
  };
});

const errorLink = onError(
  ({ networkError, graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
      forEach(graphQLErrors, ({ message, locations, extensions, path }) => {
        logger.error(`[GraphQLError]: ${message}`, {
          stack:
            extensions &&
            extensions.exception &&
            extensions.exception.stacktrace &&
            extensions.exception.stacktrace.join('\n'),
          meta: {
            locations,
            path,
            extensions,
          },
        });
      });
    }
    if (networkError) {
      if (networkError.statusCode === 401) {
        // See: https://stackoverflow.com/a/51321068/2817257
        return new Observable((observer) => {
          store
            .dispatch(refresh())
            .then(({ sessionJwt }) => {
              operation.setContext(({ headers }) => ({
                headers: {
                  ...headers,
                  authorization: sessionJwt,
                },
              }));
            })
            .then(() => {
              forward(operation).subscribe({
                next: (value) => observer.next(value),
                error: (err) => observer.error(err),
                complete: () => observer.complete(),
              });
            })
            .catch((err) => {
              // NOTE: If token exchange failed then it either refreshJwt is not
              //       present or it's not valid anymore.
              notification.error({
                message: i18next.t('jwterror'),
                duration: 0,
              });
              observer.error(err);
              // NOTE: If it's not possible to refresh token then logout user
              //       completely to make sure store is cleared.
              store.dispatch(logout());
            });
        });
      }
    }
    return undefined;
  },
);

const client = new ApolloClient({
  link: ApolloLink.from([
    authLink,
    errorLink,
    new HttpLink({
      uri: settings.public.patientGraphqlUrl,
      fetchOptions: {
        mode: 'cors',
        credentials: 'same-origin',
      },
    }),
  ]),
  cache,
  connectToDevTools: isDebug,
});

({
  store,
  persistor, // eslint-disable-line prefer-const
} = configureStore({
  history,
  client,
  cachePersistor,
}));

initI18next(store);

initialLoading = Promise.all([
  cachePersistor.restore(),
  resolveOnSelector(persistor, 'bootstrapped'),
]);

ReactDOM.render(
  <App
    store={store}
    history={history}
    client={client}
    promise={initialLoading}
  />,
  document.getElementById('root'),
);

if (process.env.NODE_ENV !== 'production') {
  if (typeof module !== 'undefined' && module.hot) {
    module.hot.accept('./containers/App', () => {
      const NextApp = require('./containers/App').default;
      ReactDOM.render(
        <NextApp
          store={store}
          history={history}
          client={client}
          promise={initialLoading}
        />,
        document.getElementById('root'),
      );
    });
  }
}

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    if (registration.waiting) {
      registration.waiting.addEventListener('statechange', (event) => {
        if (event.target.state === 'activated') {
          window.location.reload();
        }
      });

      registration.waiting.postMessage({
        type: 'SKIP_WAITING',
      });
    }
  },
});
