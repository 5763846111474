import { compose, createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import get from 'lodash/get';
import thunk from 'redux-thunk';
import storage from 'localforage';
import { routerMiddleware } from 'connected-react-router';
import { createReducer } from './rootReducer';
import { createMiddleware as createQuestionnaireMiddleware } from './questionnaire';
import { createMiddleware as createStageMiddleware } from './stage';
import { AuthError, createMiddleware as createTokenMiddleware } from './token';
import { clockMiddleware } from '../common/utils/clock';
import { set } from '../common/utilsClient/multiReducer';
import isDebug from '../utils/isDebug';
import { createNotifyError } from '../utils/notify';

let enhancer;
const identity = (x) => x;

if (isDebug) {
  enhancer = compose(
    // eslint-disable-next-line no-underscore-dangle
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__({}) // eslint-disable-line no-underscore-dangle
      : identity,
  );
} else {
  enhancer = compose(identity);
}

export const enhanceReducer = (reducer) =>
  persistReducer(
    {
      storage,
      key: 'zedoc',
      whitelist: ['storage', 'stage', 'preferences', 'token'],
    },
    reducer,
  );

export default ({ history, client, cachePersistor }) => {
  let persistor;
  let store;

  const onNewReducer = (reducer, path) => {
    // NOTE: We need to use persistor instead of store,
    //       because otherwise persist reducer will not
    //       have persist capabilities anymore.
    persistor.replaceReducer(enhanceReducer(reducer));
    if (store && path) {
      // NOTE: If this reducer was added after the store was initialized,
      //       make sure to explicitly update its initial values.
      store.dispatch(set(path, get(reducer(undefined, {}), path)));
    }
  };

  const flush = () => {
    return Promise.all([
      persistor ? persistor.flush() : Promise.resolve(),
      cachePersistor ? cachePersistor.persist() : Promise.resolve(),
    ]);
  };

  const purge = () => {
    return (
      persistor
        // First, we cleanup redux store, which include deleting all credentials.
        .purge()
        .then(() => {
          return Promise.all([
            // Next, we force ApolloClient to clean up its cache.
            cachePersistor.purge(),
            // In parallel, we use resetStore to force recomputing all queries.
            // After persistor.purge() they should all fail due to AuthError
            // and consequently DataProvider component will return empty data.
            client.resetStore().catch((err) => {
              if (
                err &&
                err.networkError &&
                err.networkError instanceof AuthError
              ) {
                return;
              }
              throw err;
            }),
          ]);
        })
        .catch(createNotifyError())
    );
  };

  // eslint-disable-next-line prefer-const
  store = createStore(
    enhanceReducer(createReducer(history, onNewReducer)),
    {},
    compose(
      applyMiddleware(
        routerMiddleware(history),
        thunk.withExtraArgument(client),
        createQuestionnaireMiddleware({
          client,
        }),
        createStageMiddleware({
          client,
        }),
        createTokenMiddleware({
          flush,
          purge,
        }),
        clockMiddleware,
      ),
      enhancer,
    ),
  );

  persistor = persistStore(store);

  if (process.env.NODE_ENV !== 'production') {
    if (typeof module !== 'undefined' && module.hot) {
      module.hot.accept('./rootReducer.js', () =>
        onNewReducer(
          // eslint-disable-next-line global-require
          require('./rootReducer').createReducer(history, onNewReducer),
        ),
      );
    }
  }

  return {
    store,
    persistor,
  };
};
